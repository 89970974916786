@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

*{
font-family: Mukta, sans-serif;
}



@keyframes moveRight {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}

.animate-marquee {
  animation: moveRight 6s linear infinite;
}


/* Define keyframes for continuous horizontal scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Apply continuous horizontal scrolling animation to testimonial cards */
